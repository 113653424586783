@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500&display=swap);
* {
  margin: 0;
  padding: 0;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  text-align: center;
  line-height: 150%;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #f8f8f8;
  padding: 0 40px;
}

#root {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.headerBar {
  width: 100%;
  position: fixed;
  z-index: 99999;
  background-color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.headerButton {
  cursor: pointer;
  font-size: 16px;
  margin: 6px 12px;
  font-weight: 1000;
  color: rgb(0, 74, 158);
  transition: opacity 0.2s ease;
}

.headerButton:hover {
  opacity: 0.4;
}

.bubbleUI {
  position: relative;
  background-color: rgba(0, 0, 0, 0) !important;
  width: 100%;
  max-width: 1000px;
  height: 500px;
  border-radius: 50px;
  background-color: white !important;
  overflow: hidden !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  margin-bottom: 10px;
}

h1 {
  font-size: 40px;
  margin-top: 80px;
  width: 100%;
  text-align: center;
  font-weight: 1000;
}
h2 {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
}

.actionContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.action {
  background-color: white;
  padding: 12px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 10px;
  border-radius: 14px;
  color: black;
  text-decoration: none;
  white-space: nowrap;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.action:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.action img {
  height: 30px;
  margin-left: 10px;
}

h3 {
  margin: 10px 0 30px 0;
  padding-top: 20px;
  font-size: 20px;
  font-weight: 1000;
  text-align: center;
}
/* 
.container {
  background-color: red;
  max-width: 100vw;
  max-height: 600px;
  overflow: scroll;
} */

.companyBubble {
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: opacity 0.1s ease;
}

.companyBubble:hover {
  opacity: 0.5;
}

.controlBar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: -10px;
}

.control {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: white;
  padding: 16px 30px;
  margin: 10px;
  border-radius: 14px;
}

.top {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 6px;
}

.controlTitle {
  font-size: 16px;
}

.controlValue {
  font-size: 16px;
  text-align: right;
}

.top img {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 6px;
  right: 6px;
  opacity: 0.2;
  transition: opacity 0.2s ease;
  cursor: pointer;
}

.top img:hover {
  opacity: 1;
}

.bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.bottom input[type="range"] {
  width: 100%;
}

.install {
  width: 260px;
  border-radius: 14px;
  overflow: hidden;
}

.codeBlock {
  width: 100%;
  max-width: 650px;
  margin-bottom: 30px;
}

h4 {
  margin-top: 60px;
  padding-top: 20px;
  margin-bottom: 60px;
  font-size: 34px;
  font-weight: 1000;
  text-align: center;
}

.propDesc {
  font-size: 20px;

  text-align: center;
  max-width: 800px;
  margin-bottom: 80px;
}

.comparisonBubbleUI {
  height: 400px;
  width: 400px;
  border-radius: 50px;
  background-color: white !important;
  overflow: hidden !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.dummyBubble {
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
}

.dummyBubbleText {
  font-size: 17px;
}

.optionContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 70px;
  text-align: center;
}

.optionName {
  font-size: 30px;
  font-weight: 1000;
  margin-bottom: 6px;
}

.optionName span {
  font-weight: 0;
  opacity: 0.5;
}

.optionDefault {
  opacity: 0.5;
  font-size: 16px;
  margin-bottom: 14px;
}

.optionDesc {
  font-size: 20px;
  margin-bottom: 20px;
  max-width: 900px;
}

.comparisonContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.beforeContainer,
.afterContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 20px;
}

.comparisonText {
  font-size: 24px;
  margin-bottom: 20px;
}

.dimensionsBubbleUI {
  position: relative;
  width: 100%;
  max-width: 800px;
  height: 500px;
  background-color: white;
  border-radius: 40px;
}

.dimensionLabel {
  position: absolute;
  font-size: 18px;
  color: white;
  font-weight: 1000;
}

.dimensionRegion {
  position: absolute;
  font-size: 24px;
  font-weight: 1000;
  color: white;
}

.optionsDesc {
  font-size: 20px;
  margin-top: -40px;
  margin-bottom: 80px;
  max-width: 600px;
}

.dimensionsDesc {
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 30px;
  max-width: 800px;
}

._1Lxpd {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
._2MD0k::-webkit-scrollbar {
  display: none;
}

._376IX {
  position: relative;
  height: 50%;
  width: 100%;
}

._3bAl3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 50%;
  padding-right: 50%;
  box-sizing: content-box;
}

._1iLpS {
  display: flex;
  flex-direction: row;
}

._2gu6r {
  display: flex;
  justify-content: center;
  align-items: center;
}

._3cleF {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
}

._3cleF > * {
  width: 100% !important;
  height: 100%;
}

._2SNHQ {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

._2pju2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.12);
}

._2MD0k {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: scroll !important;
  scroll-padding: 100px 0 0 100px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

